import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "overview",
                href: "/deviations/overview"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.deviationOverviewIcon }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Overview")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "open",
                href: "/deviations/open"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    class: "custom-icon",
                    icon: _ctx.deviationOpenIcon
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Open deviations")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "hoc-ratings",
                href: "/deviations/hoc-ratings"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    class: "custom-icon",
                    icon: _ctx.deviationCompletedIcon
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("HoC Ratings")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}